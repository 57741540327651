table {
  width: 100%;
}

.box {
  background-size: contain;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.filter_box {
  background: #00a4eb;
  border: 5px solid #00134a;
}

.aggregate_box {
  background: #00a4eb;
  border: 5px solid #00134a;
}
