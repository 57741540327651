.btn-americau {
  border: solid #000000eb 3px;
  padding: 0px 45px;
  margin-bottom: 0px;
  box-shadow: inset 7px -7px #0000006b;
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-content: space-around;
  align-items: center;
  justify-content: center;
}

.btn-americau a {
  text-decoration: none;
  font-family: "Brady Bunch Remastered";
  text-transform: uppercase;
  font-size: 20px;
  color: white !important;
}
@media (max-width: 768px) {
  .btn-americau a {
    font-size: 23px !important;
  }
}
.btn-americau:hover {
  color: #ffffff;
  cursor: pointer;
}

.green-btn {
  background: #60cb3d;
}

.green-btn:hover {
  background-color: #4aa92c;
}
.red-btn {
  background-color: #f40b0e;
}

.red-btn:hover {
  background-color: #b90406;
}
