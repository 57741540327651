@import "/node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "./assets/fonts/stylesheet.css";
@import "./assets/fonts/stylesheet2.css";
@import "/node_modules/animate.css//animate.css";

body {
  margin: 0;
  font-family: "Acme", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 60px;
  color: #002356;
  font-family: "Brady Bunch Remastered";
  text-transform: uppercase;
}

h2 {
  font-family: "Acme";
  font-size: 30px;
  color: #002356;
}

.label {
  font-size: 30px;
  color: #002356;
  font-family: "Acme";
}

.school {
  background: #4d90fe94;
  padding: 8px;
  border: 2px solid #4d90fe;
  width: 70%;
  margin: 10px auto;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Acme";
}

.school h6 {
  font-size: 25px;
  text-transform: capitalize;
}

.school p {
  margin-bottom: 0px;
}

.school-list {
  overflow-y: scroll;
  height: 300px;
  margin: auto;
}

.error {
  text-align: left;
  color: red;
  font-weight: 600;
  font-family: "Acme";
  font-size: 18px;
}

.logo {
  width: 100%;
  max-width: 200px;
  height: auto;
}

input {
  font-family: "Acme";
}

@media only screen and (max-width: 768px) {
  header {
    text-align: center;

    margin: 15px 0px;
  }

  .slider_container {
    text-align: center;
  }
}

h4 {
  font-family: "Acme";
  color: #002356;
}

.file-drop-area {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 100%;
  padding: 25px;
  border: 2px dashed rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  transition: 0.5s;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.choose-file-button {
  flex-shrink: 0;
  background-color: rgba(255, 255, 255, 0.04);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  padding: 8px 15px;
  margin-right: 10px;
  font-size: 23px;
  text-transform: uppercase;
  font-family: "Acme";
}

.file-message {
  font-size: 20px;
  text-transform: uppercase;
  font-family: "Acme";
  font-weight: 300;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
}

.file-exist {
  border: 2px dashed rgb(78, 202, 0);
}

.search-btn-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-end;
}

/* Dashboard */

.dashboard-container {
  min-height: 100vh;
  background-image: url("assets/shared/Background.png");
  background-position: center;
  background-attachment: fixed;
}

label {
  font-family: "Acme";
  font-size: 18px;
  margin-bottom: 5px;
}

.login-form form {
  margin-bottom: 15px;
  background: #f7f7f7;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 30%);
  padding: 30px;
}

.login-form {
  width: 340px;
  margin: 50px auto;
  font-size: 15px;
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  width: 70px;
  height: 70px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
